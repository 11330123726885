import { call, put } from 'redux-saga/effects';
import { fetchSignInMethodsForEmail, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import { Actions, ActionTypes } from '../actions';
import {
  highLevelLookupUser, signUpOrLogIn, UpdateUser,
} from './networks';

function* logInOrSignUp({ payload }) {
  yield put(Actions.doingLogInOrSignUp());

  const { email, password } = payload;

  try {
    const responseSignInMethods = yield call(fetchSignInMethodsForEmail, auth, email);
    if (responseSignInMethods?.length > 0) {
      const { user: authUser } = yield call(signInWithEmailAndPassword, auth, email, password);
      const token = yield call([authUser, authUser.getIdToken]);
      yield call([sessionStorage, sessionStorage.setItem], 'idToken', token);
    } else {
      const { code, data } = yield call(highLevelLookupUser, email);
      // user hasn't signed up with goHighLevel
      if (code === 422) {
        yield put(Actions.errorLogInOrSignUp({ code }));
      } else {
        try {
          // eslint-disable-next-line max-len
          const { user: authUser } = yield call(createUserWithEmailAndPassword, auth, email, password);
          if (data.contacts?.length > 0) {
            const highLevelContact = data.contacts[0];
            const user = {
              firstName: highLevelContact.firstName,
              lastName: highLevelContact.lastName,
              email: highLevelContact.email,
              phone: highLevelContact.phone,
              phoneConfirmed: true,
              emailConfirmed: true,
            };

            const token = yield call([authUser, authUser.getIdToken]);
            yield call([sessionStorage, sessionStorage.setItem], 'idToken', token);
            yield call(signUpOrLogIn);
            yield call(UpdateUser, user);
          }
        } catch (error) {
          const matches = error.message.match(/Firebase: (?<error>.+) \(/);
          if (matches) {
            yield put(Actions.errorLogInOrSignUp(matches.groups.error));
          } else {
            yield put(Actions.errorLogInOrSignUp(error.message));
          }
        }
      }
    }
    yield put(Actions.doneLogInOrSignUp());
  } catch (error) {
    const matches = error.message.match(/Firebase: (?<error>.+) \(/);
    if (matches) {
      yield put(Actions.errorLogInOrSignUp(matches.groups.error));
    } else {
      yield put(Actions.errorLogInOrSignUp(error.message));
    }
  }
}

export default [
  [ActionTypes.DO_LOG_IN_OR_SIGN_UP, logInOrSignUp],
];
