import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Actions } from '../../actions';
import './index.scss';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function PaymentProcessComplete() {
  const sessionId = useQuery().get('session_id');
  const { leadId } = useParams();
  const { done } = useSelector((state) => state.payments);
  const dispatch = useDispatch();

  useEffect(() => {
    if (sessionId) {
      dispatch(Actions.updatePayments({ sessionID: sessionId, code: leadId }));
    }
  }, [sessionId]);

  if (done === true) document.location.href = '/leads';

  return (
    <div className="lead_details">
      <h2>
        Thank you!
      </h2>
    </div>
  );
}
