/* eslint-disable no-shadow */
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Breadcrumb } from 'react-bootstrap';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { useQueryParams } from '../../utils';
import { Actions } from '../../actions';
import { professionalCategories } from '../../utils/const';

function SimpleBreadcrumb({ match }) {
  const item = match.pathname.replace(/^\/-?([a-zA-Z])([a-zA-Z]*)$/, (match, p1, p2) => p1.toUpperCase() + p2.toLowerCase());

  return (
    <Breadcrumb.Item
      key={item}
      active
    >
      {item}
    </Breadcrumb.Item>
  );
}

function RootBreadcrumb() {
  const navigation = useSelector((state) => state.navigation);

  return (
    <Breadcrumb.Item
      key="Home"
      href={navigation.referrer}
    >
      Hyponia
    </Breadcrumb.Item>
  );
}

function SearchBreadcrumb({ match, location }) {
  const search = useSelector((state) => state.search);

  const breadcrumb = search.city && search.state ? `${search.city}, ${search.state}` : 'Location';
  return (
    <Breadcrumb.Item
      key={breadcrumb}
      active={location.pathname === match.pathname}
      linkAs={Link}
      linkProps={{
        to: match.pathname,
        state: {
          ...search,
        },
      }}
    >
      {breadcrumb}
    </Breadcrumb.Item>
  );
}

function GuideBreadcrumb({ match }) {
  const { stepInfo } = useSelector((state) => state.timeline);
  const search = useSelector((state) => state.search);

  return (
    <Breadcrumb.Item
      key="Guide"
      active={!stepInfo}
      linkAs={Link}
      linkProps={{
        to: match.pathname,
        state: {
          ...search,
        },
      }}
    >
      Guide
    </Breadcrumb.Item>
  );
}

function GuideCategoryBreadcrumb() {
  const { stepInfo } = useSelector((state) => state.timeline);

  const breadcrumb = stepInfo?.name ?? null;

  return (
    <Breadcrumb.Item
      key={breadcrumb}
      active
    >
      {breadcrumb}
    </Breadcrumb.Item>
  );
}

function ProfessionalsBreadcrumb({ match }) {
  const { category } = useSelector((state) => state.professionals);
  const search = useSelector((state) => state.search);

  return (
    <Breadcrumb.Item
      key="Professionals"
      active={!category}
      linkAs={Link}
      linkProps={{
        to: match.pathname,
        state: {
          ...search,
        },
      }}
    >
      Professionals
    </Breadcrumb.Item>
  );
}

function ProfessionalsCategoryBreadcrumb() {
  const { category } = useSelector((state) => state.professionals);

  const breadcrumb = category ? professionalCategories.find((p) => p.enum === category).name : null;

  return (
    <Breadcrumb.Item
      key={breadcrumb}
      active
    >
      {breadcrumb}
    </Breadcrumb.Item>
  );
}

function LeadsBreadcrumb({ match }) {
  const { lead } = useSelector((state) => state.leads);

  return (
    <Breadcrumb.Item
      key="Leads"
      active={!lead?.code}
      linkAs={Link}
      linkProps={{
        to: match.pathname,
      }}
    >
      Leads
    </Breadcrumb.Item>
  );
}

function LeadBreadcrumb() {
  const { lead } = useSelector((state) => state.leads);

  if (lead) {
    return (
      <Breadcrumb.Item
        key={lead.code}
        active
      >
        {lead.code}
      </Breadcrumb.Item>
    );
  }
}

const routes = [
  { path: '/', breadcrumb: RootBreadcrumb, exact: true },
  { path: '/location', breadcrumb: SearchBreadcrumb, exact: true },
  { path: '/location/guide', breadcrumb: GuideBreadcrumb },
  { path: '/location/guide/:category', breadcrumb: GuideCategoryBreadcrumb },
  { path: '/location/professionals', breadcrumb: ProfessionalsBreadcrumb },
  { path: '/location/professionals/:category', breadcrumb: ProfessionalsCategoryBreadcrumb },
  { path: '/leads/', breadcrumb: LeadsBreadcrumb },
  { path: '/leads/:leadId', breadcrumb: LeadBreadcrumb },
  { path: '/login', breadcrumb: SimpleBreadcrumb },
  { path: '/user', breadcrumb: SimpleBreadcrumb },
];

function Breadcrumbs() {
  const breadcrumbs = useBreadcrumbs(routes);
  const dispatch = useDispatch();
  const location = useLocation();

  // from react navigation
  useEffect(() => {
    if (location.state) {
      const search = location.state;

      if (search) {
        dispatch(Actions.searchChanged(search));
      }
    }
  }, [location.state]);

  // from url
  useEffect(() => {
    if (window.location.search) {
      const { search, state, city } = useQueryParams();

      if (search && city && state) {
        dispatch(Actions.searchChanged({ search, state, city }));
      }
    }
  }, [window.location.search]);

  return (
    <Breadcrumb>
      {breadcrumbs.map(({
        breadcrumb,
      }) => (
        breadcrumb
      ))}
    </Breadcrumb>
  );
}

export default Breadcrumbs;
