import { call, put } from 'redux-saga/effects';
import { Actions, ActionTypes } from '../actions';

import {
  SavePayments,
  UpdatePayments,
} from './networks';

function* payments({ payload }) {
  yield put(Actions.doingPayments());
  const response = yield call(SavePayments, payload);
  if (!response.successful) {
    yield put(Actions.handleErrors());
    yield put(Actions.errorPayments());
  } else {
    yield put(Actions.donePayments(response));
  }
}

function* updatePayments({ payload }) {
  yield put(Actions.doingPayments());
  const response = yield call(UpdatePayments, payload);
  if (!response.successful) {
    yield put(Actions.handleErrors());
    yield put(Actions.errorPayments());
  } else {
    yield put(Actions.donePayments(response));
  }
}

export default [
  [ActionTypes.DO_PAYMENTS, payments],
  [ActionTypes.UPDATE_PAYMENTS, updatePayments],
];
