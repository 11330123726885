/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Col, Row, Form,
} from 'react-bootstrap';
import moment from 'moment';

export default function Attributes({ lead }) {
  return (
    <section>
      <Row>
        <Col
          md="auto"
          xs={12}
          className="flex-md-grow-1 flex-md-shrink-1 content"
        >
          <Row>
            <Col xs={12} md={6} className="form-item">
              <Form.Group controlId="city">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  value={lead.location.city}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} className="form-item">
              <Form.Group controlId="state">
                <Form.Label>State</Form.Label>
                <Form.Control
                  type="text"
                  value={lead.location.state}
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} className="form-item">
              <Form.Group controlId="dateSubmitted">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="text"
                  value={moment(lead.dateSubmitted).format('MM/DD/YYYY HH:mm')}
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  );
}
