/* eslint-disable no-shadow */
import React, { useEffect } from 'react';
import { Route, Navigate } from 'react-router-dom';
import SlideRoutes from 'react-slide-routes';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { auth } from './firebase';
import { Actions } from './actions';
import { getUser } from './sagas/networks';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Breadcrumbs from './components/common/Breadcrumbs';
import Search from './components/common/Search';
import Login from './components/Login';
import Logout from './components/Logout';
import GuideCategories from './components/GuideCategories';
import Guide from './components/Guide';
import ProfessionalCategories from './components/ProfessionalCategories';
import Professionals from './components/Professionals';
import Leads from './components/Leads';
import Lead from './components/Lead';
import User from './components/User';
import PrivateRoutes from './utils/PrivateRoutes';
import PaymentProcessComplete from './components/Lead/PaymentProcessComplete';

ReactGA.initialize('UA-165836730-1');

function App() {
  const dispatch = useDispatch();

  const { isLoggingIn, user } = useSelector((state) => state.user);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const onAuthStateChanged = async (authUser) => {
    if (authUser) {
      if (!(isLoggingIn)) {
        const { data: user } = await getUser();
        dispatch(Actions.updateUser(user));
      }
    }
  };

  useEffect(() => {
    const subscriber = auth.onAuthStateChanged(onAuthStateChanged);
    return subscriber; // unsubscribe on unmount
  }, [isLoggingIn]);

  return (
    <div className="d-flex flex-column h-100">
      <Header />
      <div className="d-flex flex-column flex-grow-1">
        <div className="d-flex container breadcrumbs-container">
          <div className="d-flex flex-grow-1">
            <Breadcrumbs />
          </div>
          {user
        && <Logout />}
        </div>
        <div className="container content-container d-flex flex-column flex-grow-1">
          <SlideRoutes duration={500}>
            <Route path="/" element={<Navigate to="/location" />} />
            <Route path="/location" element={<Search />} />
            <Route path="/location/guide" element={<GuideCategories />} />
            <Route path="/location/guide/:category" element={<Guide />} />
            <Route path="/location/professionals" element={<ProfessionalCategories />} />
            <Route path="/location/professionals/:category" element={<Professionals />} />
            <Route path="/leads/" element={<Leads />} />
            <Route path="/leads/:leadId" element={<Lead />} />
            <Route path="/leads/:leadId/paymentProcessComplete" element={<PaymentProcessComplete />} />
            <Route path="/login" element={<Login />} />
            <Route element={<PrivateRoutes />}>
              <Route path="/user" element={<User />} />
            </Route>
          </SlideRoutes>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
