import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Form, Button, Row, Col,
} from 'react-bootstrap';
import { Actions } from '../../actions';
import './index.scss';

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    isLoggingIn, logInError, goHighLevelNoAccountError, user,
  } = useSelector((state) => state.user);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (user) {
      navigate('/user');
    }
  }, [user]);

  const handleSubmit = () => {
    dispatch(Actions.doLogInOrSignUp({ email, password }));
  };

  return (
    <div className="auth-container">
      <h3 className="align-self-center">Log in or Sign Up</h3>
      <Row className="mt-3">
        <Col xs={0} md={3} />
        <Col md={6}>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                isInvalid={logInError}
              />
            </Form.Group>

            <Form.Group controlId="formBasicPassword" className="mt-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                isInvalid={logInError}
              />
              <Form.Control.Feedback type="invalid">
                {logInError}
              </Form.Control.Feedback>
              {goHighLevelNoAccountError
              && (
              <div className="invalid-feedback d-block">
                This email is not registered. Please register for access at
                {' '}
                <a href="https://go.hyponia.com/hyponia">Hyponia</a>
              </div>
              )}
            </Form.Group>
            <div className="d-flex flex-row-reverse mb-3 pt-3">
              <Button variant="primary" type="submit" disabled={isLoggingIn}>
                Submit
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
