/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Col, Row, Form,
} from 'react-bootstrap';

export default function UserInfo({ lead, purchased }) {
  return (
    <section>
      <h3>Contact Information</h3>
      <Row className={purchased ? '' : 'blur'}>
        <Col
          md="auto"
          xs={12}
          className="flex-md-grow-1 flex-md-shrink-1 content"
        >
          <Row>
            <Col xs={12} md={6} className="form-item">
              <Form.Group controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  value={purchased ? lead.userInfo.firstName : 'Please purchase'}
                  disabled
                />
                {/* <Form.Control.Feedback type="invalid">
                  Please enter a valid first name.
                </Form.Control.Feedback> */}
              </Form.Group>
            </Col>
            <Col xs={12} md={6} className="form-item">
              <Form.Group controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  value={purchased ? lead.userInfo.lastName : 'Please purchase'}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} className="form-item">
              <Form.Group controlId="email">
                <Form.Label>Email (to receive quotes)</Form.Label>
                <Form.Control
                  type="text"
                  value={purchased ? lead.userInfo.email : 'Please purchase'}
                  disabled
                />
                {/* <Form.Control.Feedback type="invalid">
                  Please enter a valid email address.
                </Form.Control.Feedback> */}
              </Form.Group>
            </Col>
            <Col xs={12} md={6} className="form-item">
              <Form.Group controlId="phone">
                <Form.Label>Phone (will not be shared with anyone)</Form.Label>
                <Form.Control
                  type="text"
                  value={purchased ? lead.userInfo.phone || '' : 'Please purchase'}
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  );
}
