export default {
  mode: 'payment',
  success_url: 'http://localhost:3005/leads/NY782',
  line_items: [{
    price_data: {
      currency: 'USD',
      product_data: { name: 'Initial Payment Setup' },
      unit_amount: 100,
    },
    quantity: 1,
  }],
};
