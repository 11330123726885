import { ActionTypes } from '../actions';

const defaultState = {
  token: sessionStorage.getItem('idToken'),
};

// eslint-disable-next-line default-param-last
export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case ActionTypes.DOING_LOG_IN_OR_SIGN_UP:
        return {
          ...state.user,
          isLoggingIn: true,
        };
      case ActionTypes.ERROR_LOG_IN_OR_SIGN_UP:
      {
        const { code } = action.payload;
        if (code) {
          return {
            ...state,
            isLoggingIn: false,
            goHighLevelNoAccountError: true,
          };
        }
        return {
          ...state,
          isLoggingIn: false,
          logInError: action.payload,
        };
      }
      case ActionTypes.DONE_LOG_IN_OR_SIGN_UP:
        return {
          ...state,
          isLoggingIn: false,
          isLoginComplete: true,
        };
      case ActionTypes.UPDATE_USER:
        return {
          ...state,
          user: action.payload,
        };
      default:
        return state;
    }
  } catch (error) {
    console.log('Error in timeline reducer:', error);
  }

  return null;
};
