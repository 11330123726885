import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { professionalCategories } from '../../utils/const';
import { Actions } from '../../actions';
import './index.scss';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

export default function Leads() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const gridRef = useRef();

  const { leads } = useSelector((state) => state.leads);

  const [rowData, setRowData] = useState([]);

  const [columnDefs] = useState([
    { field: 'id', headerName: 'Lead Id' },
    { field: 'category', headerName: 'Type' },
    { field: 'location', headerName: 'Location' },
    {
      field: 'dateSubmitted',
      headerName: 'Date',
      valueFormatter: (params) => moment(params.value).format('MM/DD/YYYY HH:mm'),
    },
    {
      field: 'price',
      headerName: 'Price',
      valueFormatter: (params) => `$${params.value}`,
    },
    { field: 'phoneConfirmed', headerName: 'Phone\nConfirmed' },
  ]);

  useEffect(() => {
    dispatch(Actions.getLeads());
  }, []);

  useEffect(() => {
    if (leads) {
      setRowData(leads.filter((item) => item.code).map((item) => ({
        category: professionalCategories.find((p) => p.enum === item.category)?.name,
        id: item.code,
        location: `${item.location.city}, ${item.location.state}`,
        price: item.price,
        dateSubmitted: item.dateSubmitted,
        phoneConfirmed: item.userInfo.phoneConfirmed,
      })));
    }
  }, [leads]);

  function onFirstDataRendered(params) {
    window.setTimeout(() => {
      const colIds = [];
      gridRef.current.columnApi.getColumns().forEach((column) => {
        if (!(column.getId() === 'phoneConfirmed' || column.getId() === 'emailConfirmed')) {
          colIds.push(column.getId());
        }
      });
      params.columnApi.autoSizeColumns(colIds);
    }, 0);
  }

  const defaultColDef = {
    sortable: true,
    resizable: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
  };

  return (
    <div className="d-flex h-100">
      <div className="ag-theme-alpine grid-container">
        <AgGridReact
          ref={gridRef}
          onRowClicked={(e) => navigate(e.data.id)}
          rowData={rowData}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          onFirstDataRendered={(params) => onFirstDataRendered(params)}
        />
      </div>
    </div>
  );
}
