import { call, put } from 'redux-saga/effects';
import { Actions, ActionTypes } from '../actions';

import { GetLeads, GetLead } from './networks';

function* getLeads() {
  yield put(Actions.loadingLeads());

  const response = yield call(GetLeads);

  if (!response.successful) {
    console.log('Error', response.code);
    yield put(Actions.handleErrors(response));
  } else {
    yield put(Actions.loadedLeads({
      leads: response.data,
    }));
  }
}

function* getLead({ payload }) {
  yield put(Actions.loadingLead());

  const response = yield call(GetLead, payload);

  if (!response.successful) {
    console.log('Error', response.code);
    yield put(Actions.handleErrors(response));
  } else {
    yield put(Actions.loadedLead({
      lead: response.data,
    }));
  }
}

export default [
  [ActionTypes.GET_LEADS, getLeads],
  [ActionTypes.GET_LEAD, getLead],
];
