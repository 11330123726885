/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Col, Row, Form,
} from 'react-bootstrap';
import { NumericFormat } from 'react-number-format';
import {
  // WhenPlanList,
  CreditScoreList,
  // BoroughList,
  MaxPriceList,
  DownpaymentPercentList,
  // MoverTypePropertyList,
  PropertyTypeList,
  PropertyUseList,
} from '../../../utils/quoteConstants';

export default function MortgageBrokers({ lead }) {
  return (
    <section>
      <h3>Details</h3>
      <Row>
        <Col
          md="auto"
          xs={12}
          className="flex-md-grow-1 flex-md-shrink-1 content"
        >
          <Row>
            <Col xs={12} md={6} className="form-item">
              <Form.Group controlId="propertyType">
                <Form.Label>Property Type</Form.Label>
                <Form.Control
                  value={lead.purchaseInfo.propertyType}
                  as="select"
                  disabled
                >
                  {PropertyTypeList.map((item, i) => (
                    <option key={i} value={item}>
                      {item}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs={12} md={6} className="form-item">
              <Form.Group controlId="propertyUse">
                <Form.Label>Property Use</Form.Label>
                <Form.Control
                  value={lead.purchaseInfo.propertyUse}
                  as="select"
                  disabled
                >
                  {PropertyUseList.map((item, i) => (
                    <option key={i} value={item}>
                      {item}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} className="form-item">
              <Form.Group controlId="maximumPrice">
                <Form.Label>Maximum Price</Form.Label>
                <Form.Control
                  value={lead.purchaseInfo.maximumPrice}
                  as="select"
                  disabled
                >
                  {MaxPriceList.map((item, i) => (
                    <option key={i} value={item.value}>
                      {item.value === 0 ? '' : `$${item.name}`}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs={12} md={6} className="form-item">
              <Form.Group controlId="downPaymentPercent">
                <Form.Label>Down payment</Form.Label>
                <Form.Control
                  value={lead.financialInfo.downPaymentPercent}
                  as="select"
                  disabled
                >
                  {DownpaymentPercentList.map((item, i) => (
                    <option key={i} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} className="form-item">
              <Form.Group controlId="creditScore">
                <Form.Label>Estimated Credit Score</Form.Label>
                <Form.Control
                  value={lead.financialInfo.creditScore}
                  as="select"
                  disabled
                >
                  {CreditScoreList.map((item, i) => (
                    <option key={i} value={item}>
                      {item}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs={12} md={6} className="form-item">
              <Form.Group controlId="householdIncome">
                <Form.Label>Annual Income of borrower(s)</Form.Label>
                <NumericFormat
                  value={lead.financialInfo.householdIncome}
                  thousandSeparator
                  prefix="$"
                  disabled
                  inputMode="numeric"
                  className="form-control"
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  );
}
