/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Col, Row, Form,
} from 'react-bootstrap';
import { MaxPriceList } from '../../../utils/quoteConstants';

export default function RealEstateAgents({ lead }) {
  return (
    <section>
      <h3>Details</h3>
      <Row>
        <Col
          md="auto"
          xs={12}
          className="flex-md-grow-1 flex-md-shrink-1 content"
        >
          <Row>
            <Col xs={12} md={6} className="form-item">
              <Form.Group controlId="maximumPrice">
                <Form.Label>Maximum Price</Form.Label>
                <Form.Control
                  value={lead.purchaseInfo.maximumPrice}
                  as="select"
                  disabled
                >
                  {MaxPriceList.map((item, i) => (
                    <option key={i} value={item.value}>
                      {item.value === 0 ? '' : `$${item.name}`}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="form-item">
              <Form.Group controlId="additionalInformation">
                <Form.Label>
                  Additional home buying criteria (optional)
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  value={lead.purchaseInfo.additionalInformation}
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="form-item-single-checkbox">
              <Form.Group controlId="sellCurrentProperty">
                <Form.Check
                  type="checkbox"
                  defaultChecked={lead.purchaseInfo.sellCurrentProperty}
                  label="I also need help selling my current home"
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  );
}
