import React from 'react';
import { createRoot } from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore from './store';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.scss';

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line no-console
  console.log('REACT_APP_API_URL=', process.env.REACT_APP_API_URL);
}

const container = document.getElementById('app');
const root = createRoot(container);

root.render(
  <Router>
    <Provider store={configureStore()}>
      <App />
    </Provider>
  </Router>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
