/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { professionalCategories } from '../../utils/const';
import { getIconName } from '../../utils';

function ProfessionalCategories() {
  const navigate = useNavigate();

  const search = useSelector((state) => state.search);

  return (
    <ul className="categories">
      {
      professionalCategories.map((professional, index) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <li
          key={professional.id}
          // eslint-disable-next-line max-len
          onClick={() => navigate(`${professionalCategories.find((p) => p.enum === professional.enum).enum}`, {
            state: {
              ...search,
            },
          })}
        >
          <img alt="" className={`icon icon-${getIconName(professional.name)}`} />
          {`${index + 1}. ${professional.name}`}
          <img alt="" className="icon icon-caret icon-right" />
        </li>
      ))
    }
    </ul>
  );
}

export default ProfessionalCategories;
