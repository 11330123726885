/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Col, Row, Form,
} from 'react-bootstrap';
import { formatDisplayDate } from '../../../utils';
import StreetInput from '../../common/StreetInput';
import { ElevatorList } from '../../../utils/quoteConstants';

export default function Movers({ lead }) {
  return (
    <>
      <section>
        <Row id="movers-from">
          <Col
            md="auto"
            xs={12}
            className="flex-md-grow-1 flex-md-shrink-1 content"
          >
            <Row>
              <Col xs={12} md={6} className="form-item">
                <Form.Group controlId="MoveDate">
                  <Form.Label>Expected date of move</Form.Label>
                  <Form.Control
                    type="date"
                    value={formatDisplayDate(lead.userMove.moveDate)}
                    placeholder="MM/DD/YYYY"
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                md={6}
                className="d-flex d-align-items-center form-item"
              >
                <Form.Group>
                  <Form.Label>
                    Does your building have an elevator?
                  </Form.Label>
                  <div className="form-radios">
                    {ElevatorList.map((item, index) => (
                      <Form.Check
                        name="FromHasElevator"
                        key={index}
                        label={item}
                        type="radio"
                        id={`inline-radio-${index}`}
                        disabled
                        checked={
                              !(
                                // eslint-disable-next-line no-bitwise
                                lead.userMove.fromProperty.hasElevator ^ (item === 'Yes')
                              )
                            }
                      />
                    ))}
                  </div>
                </Form.Group>
              </Col>
              {!lead.userMove.fromProperty.hasElevator && (
              <Col xs={12} md={6} className="form-item">
                <Form.Group controlId="FromFloor">
                  <Form.Label>
                    What floor is your home located on?
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={lead.userMove.fromProperty.floor}
                  >
                    {[1, 2, 3, 4, 5, 6].map((i) => (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              )}
            </Row>
            <StreetInput
              label="Address - Moving From"
              addresses={lead.userMove.fromProperty.address}
            />
          </Col>
        </Row>
      </section>
      <section>
        <Row id="movers-to">
          <Col
            md="auto"
            xs={12}
            className="flex-md-grow-1 flex-md-shrink-1 content"
          >
            <Row>
              <Col xs={12} md={6} className="d-flex d-align-items-center form-item">
                <Form.Group>
                  <Form.Label>Does your building have an elevator?</Form.Label>
                  <div className="form-radios">
                    {ElevatorList.map((item, index) => (
                      <Form.Check
                        name="ToHasElevator"
                        key={index}
                        label={item}
                        type="radio"
                        id={`inline-radio-${index}-to`}
                        checked={
                       // eslint-disable-next-line no-bitwise
                       !(lead.userMove.toProperty.hasElevator ^ (item === 'Yes'))
                     }
                        disabled
                      />
                    ))}
                  </div>
                </Form.Group>
              </Col>
              {!lead.userMove.toProperty.hasElevator && (
              <Col xs={12} md={6} className="form-item">
                <Form.Group controlId="ToFloor">
                  <Form.Label>What floor is your home located on?</Form.Label>
                  <Form.Control
                    as="select"
                    value={lead.userMove.toProperty.floor}
                    disabled
                  >
                    {[1, 2, 3, 4, 5, 6].map((i) => (
                      <option key={i} value={i}>
                        {i}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              )}
            </Row>
            <StreetInput
              label="Address - Moving To"
              addresses={lead.userMove.toProperty.address}
              disabled
            />
          </Col>
        </Row>
      </section>
    </>
  );
}
