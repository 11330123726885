import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AutoComplete from './AutoComplete';
import './Search.scss';
import '../../app.scss';

function Search() {
  const search = useSelector((state) => state.search);

  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    if (search.search) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [search]);

  return (
    <div className="d-flex flex-column">
      <svg xmlns="http://www.w3.org/2000/svg" className="d-none">
        <symbol id="professionals" viewBox="0 0 39 44" xmlns="http://www.w3.org/2000/svg">
          <path d="M25.7447 23.9834C26.1552 23.3353 28.2186 19.9979 28.4936 17.9515C28.5273 17.7012 28.497 17.4462 28.4059 17.2105C28.1201 16.4714 25.9958 12.1804 23.4738 10.9706C23.4717 10.9694 21.5094 10.0597 21.5094 10.0597L20.6534 9.33725L19.3327 10.6518L20.6281 19.3127C20.6324 19.3418 20.6251 19.3714 20.6078 19.3952L19.2872 21.2026C19.2661 21.2314 19.2327 21.2484 19.197 21.2484C19.1614 21.2484 19.1277 21.2314 19.1068 21.2026L17.7862 19.3952C17.7689 19.3714 17.7616 19.3418 17.766 19.3127L19.0616 10.6506L17.7421 9.33716L16.8857 10.0596C16.8857 10.0596 14.9343 10.9519 14.8855 10.9752C12.364 12.185 10.2396 16.4759 9.95371 17.2151C9.86265 17.4509 9.83252 17.7056 9.86581 17.9562C10.1409 20.0025 12.2042 23.3399 12.6149 23.9882C12.9009 24.4388 13.3867 24.6855 13.8837 24.6854C14.1451 24.6854 14.4095 24.616 14.6492 24.4733C14.77 24.8124 14.942 25.1281 15.1537 25.4177L15.1546 42.1796C15.1548 43.185 15.9699 43.9998 16.975 43.9997C17.9807 43.9997 18.7955 43.1848 18.7953 42.1795L18.8147 27.4046C18.9431 27.4154 19.0712 27.4217 19.1973 27.4217C19.319 27.4217 19.4422 27.4157 19.5656 27.4054L19.5652 42.1795C19.5652 43.1849 20.38 43.9997 21.3854 43.9997C22.3905 43.9997 23.2055 43.1848 23.2055 42.1796L23.2058 25.4649C23.4308 25.1664 23.6139 24.8393 23.7406 24.4865C23.9724 24.6174 24.2253 24.6808 24.4757 24.6808C24.9726 24.681 25.4587 24.4341 25.7447 23.9834Z" />
          <path d="M19.1974 9.27757C21.3916 9.27757 23.0296 7.23244 23.0668 3.80389C23.0906 1.42407 21.9575 0 19.1974 0C16.437 0 15.3039 1.42407 15.3273 3.80389C15.365 7.23244 17.0021 9.27757 19.1974 9.27757Z" />
          <path fillRule="evenodd" clipRule="evenodd" d="M34.2416 16.9741C36.3053 17.9639 38.0435 21.475 38.2775 22.0797C38.3522 22.2728 38.3769 22.4814 38.349 22.6863C38.1239 24.3607 36.4357 27.0915 36.0997 27.6219C35.8658 27.9905 35.4682 28.1926 35.0615 28.1926C34.8567 28.1926 34.6497 28.1407 34.4602 28.0336C34.3564 28.3221 34.2066 28.5898 34.0225 28.8341L34.0222 42.5108C34.0222 43.3333 33.3555 44 32.5332 44C31.7103 44 31.0437 43.3334 31.0437 42.5107L31.044 30.4219C30.9433 30.4302 30.8425 30.4352 30.7428 30.4352C30.6395 30.4352 30.535 30.43 30.4299 30.4213L30.4138 42.5106C30.414 43.3332 29.7475 44 28.9245 44C28.1023 44.0001 27.4351 43.3335 27.4351 42.5108L27.4342 28.7954C27.2612 28.5586 27.1204 28.3001 27.0216 28.0228C26.8253 28.1395 26.609 28.1961 26.3951 28.1961C25.9884 28.1963 25.5909 27.9944 25.3569 27.6257C25.2201 27.4096 24.859 26.8284 24.4636 26.1022V25.938H24.4762C25.318 25.938 26.0974 25.5617 26.6184 24.9178L26.8587 24.5747C27.5241 23.5165 29.446 20.307 29.74 18.1198C29.802 17.6608 29.7463 17.1896 29.579 16.7571C29.5742 16.7445 29.4401 16.4305 29.1948 15.9394L29.5523 15.6377L30.6455 16.7256C30.6453 16.7264 30.645 16.7271 30.6446 16.7279C30.6443 16.7287 30.6439 16.7295 30.6437 16.7304L29.7299 24.02C29.7267 24.0413 29.732 24.0628 29.7447 24.0802L30.7086 25.3995C30.7239 25.4207 30.7485 25.433 30.7745 25.433C30.8003 25.433 30.8249 25.4207 30.8403 25.3995L31.8041 24.0802C31.8168 24.0628 31.822 24.0413 31.8188 24.02L30.8381 16.7305C30.838 16.7302 30.8379 16.7299 30.8378 16.7297C30.8377 16.7294 30.8375 16.7291 30.8375 16.7289L31.9338 15.6377L32.6343 16.2288C32.6343 16.2288 34.2398 16.9731 34.2416 16.9741ZM34.6278 20.6457L34.6272 25.2964C35.1426 24.3808 35.6623 23.3304 35.8512 22.6563C35.5984 22.1286 35.1371 21.3438 34.6278 20.6457Z" />
          <path d="M30.7426 15.5887C32.538 15.5887 33.8783 13.9153 33.9088 11.1098C33.9281 9.16259 33.0012 7.99736 30.7426 7.99736C28.4837 7.99736 27.5567 9.16259 27.5761 11.1098C27.6068 13.9153 28.9465 15.5887 30.7426 15.5887Z" />
          <path fillRule="evenodd" clipRule="evenodd" d="M11.5306 24.6255L11.7387 24.9191C12.2593 25.5647 13.0402 25.9428 13.8838 25.9427H13.8967V26.0984C13.5014 26.8245 13.1401 27.4057 13.0034 27.6219C12.7694 27.9904 12.3715 28.1925 11.965 28.1925C11.7601 28.1925 11.5532 28.1405 11.3637 28.0335C11.2599 28.322 11.1102 28.5896 10.926 28.834L10.9258 42.5107C10.9258 43.3332 10.2589 43.9999 9.43637 43.9999C8.61367 43.9999 7.94707 43.3333 7.94707 42.5106L7.94736 30.4218C7.84645 30.4301 7.74592 30.4352 7.64625 30.4352C7.54294 30.4352 7.43811 30.43 7.33327 30.4212L7.3172 42.5106C7.3173 43.3332 6.6508 44 5.8279 44C5.00549 44.0001 4.33832 43.3335 4.33832 42.5108L4.33755 28.7954C4.16413 28.5585 4.02362 28.3001 3.92481 28.0228C3.72863 28.1394 3.51236 28.1962 3.29848 28.1962C2.89167 28.1962 2.49424 27.9945 2.26028 27.6258C1.92406 27.0954 0.235993 24.3645 0.0108288 22.69C-0.0165277 22.4851 0.00815051 22.2768 0.0825677 22.0837C0.316532 21.4788 2.05472 17.9679 4.11803 16.9779C4.15782 16.9589 5.75473 16.2289 5.75473 16.2289L6.45557 15.6378L7.54734 16.7247C7.54712 16.7257 7.54675 16.7266 7.54638 16.7274C7.54593 16.7284 7.54549 16.7294 7.54533 16.7306L6.63157 24.0202C6.62832 24.0415 6.63368 24.0631 6.6463 24.0804L7.61028 25.3997C7.62549 25.4208 7.65007 25.4333 7.67609 25.4333C7.70201 25.4333 7.7265 25.4208 7.7419 25.3997L8.70578 24.0804C8.71851 24.0631 8.72377 24.0415 8.72051 24.0202L7.73979 16.7307L7.7397 16.7302L8.83711 15.6379L9.17582 15.9237C8.92368 16.4267 8.78594 16.7494 8.78125 16.7622C8.61434 17.1935 8.55858 17.664 8.61979 18.124C8.9187 20.3463 10.8937 23.6171 11.5306 24.6255ZM3.76067 20.6098C3.24004 21.3177 2.76618 22.1226 2.5084 22.6601C2.70085 23.3482 3.23669 24.4252 3.76172 25.3522L3.76067 20.6098Z" />
          <path d="M7.64598 15.5887C9.44127 15.5887 10.7815 13.9153 10.8122 11.1098C10.8313 9.16259 9.90432 7.99736 7.64598 7.99736C5.38716 7.99736 4.4601 9.16259 4.47942 11.1098C4.51013 13.9153 5.84992 15.5887 7.64598 15.5887Z" />
        </symbol>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" className="d-none">
        <symbol id="guide" viewBox="0 0 42 44" xmlns="http://www.w3.org/2000/svg">
          <path d="M22.0546 22.8811V36.3722C23.188 35.4982 24.5997 34.998 26.1008 34.998H35.1132V18.8347H26.1008C23.8697 18.8347 22.0546 20.6499 22.0546 22.8811Z" />
          <path d="M22.3605 40.0782L22.0546 40.8179V40.8747V44C23.6286 42.4556 25.7702 41.5478 28.0737 41.5478H41.5305V22.3928H37.6918V37.5765H26.1008C24.4571 37.5765 22.9889 38.5584 22.3605 40.0782Z" />
          <path d="M20.7653 18.9599C21.8164 17.5334 23.425 16.5431 25.2667 16.3105C25.388 15.8954 25.4536 15.4568 25.4536 15.0028C25.4536 12.4176 23.3504 10.3145 20.7653 10.3145C18.1801 10.3145 16.077 12.4177 16.077 15.0028C16.077 15.4567 16.1428 15.8953 16.2639 16.3105C18.1056 16.5431 19.7142 17.5334 20.7653 18.9599Z" />
          <path d="M6.41736 34.9979H15.4297C16.9309 34.9979 18.3425 35.498 19.476 36.3721V22.8811C19.476 20.6499 17.6608 18.8348 15.4297 18.8348H6.41736L6.41736 34.9979Z" />
          <path d="M3.8387 22.3928H0V41.5478H13.4568C15.7606 41.5478 17.9019 42.4554 19.476 44V40.8747V40.8176L19.1701 40.0782C18.5416 38.5584 17.0734 37.5765 15.4297 37.5765H3.8387V22.3928Z" />
          <path d="M5.76245 11.5518V16.2561H13.6076C13.5362 15.8489 13.4983 15.4302 13.4983 15.0027C13.4983 10.9957 16.7582 7.73586 20.7652 7.73586C24.7721 7.73586 28.0321 10.9957 28.0321 15.0027C28.0321 15.4303 27.9938 15.8489 27.9222 16.2561H35.7679V11.5518L32.6831 10.4982C32.589 10.2492 32.4871 10.0031 32.3777 9.76076L33.814 6.83437L28.9336 1.95399L26.0071 3.39028C25.7648 3.28086 25.5187 3.17892 25.2698 3.08472L24.2162 0H17.3143L16.2607 3.0848C16.0118 3.17892 15.7656 3.28086 15.5233 3.39037L12.5969 1.95408L7.71653 6.83446L9.15282 9.76085C9.0434 10.0031 8.94146 10.2492 8.84734 10.4982L5.76245 11.5518Z" />
        </symbol>
      </svg>
      <div className="autocomplete-container">
        <AutoComplete />
      </div>
      <div className="d-flex flex-column align-items-center btn-group-vertical mt-3 gap-2" role="group" aria-label="Select Professionals or Guide">
        <Link
          to="professionals"
          state={{
            ...search,
          }}
          type="button"
          className={`btn btn-outline-hyponia ${buttonDisabled && 'disabled-btn'}`}
        >
          <svg>
            <use xlinkHref="#professionals" />
          </svg>
          <div>
            Show me the Professionals here
          </div>
        </Link>
        <Link
          to="guide"
          state={{
            ...search,
          }}
          type="button"
          className={`btn btn-outline-hyponia ${buttonDisabled && 'disabled-btn'}`}
        >
          <svg>
            <use xlinkHref="#guide" />
          </svg>
          <div>
            Show me how to buy here
          </div>
        </Link>
      </div>
    </div>
  );
}

export default Search;
