/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Col, Row, Form,
} from 'react-bootstrap';
import { formatDisplayDate } from '../../../utils';
import StreetInput from '../../common/StreetInput';
import { PropertyTypeList, OccupancyList } from '../../../utils/quoteConstants';

export default function HomeInspectors({ lead }) {
  return (
    <section>
      <Row>
        <Col
          md="auto"
          xs={12}
          className="flex-md-grow-1 flex-md-shrink-1 content"
        >
          <Row>
            <Col
              xs={12}
              md={6}
              className="d-flex d-align-items-center form-item"
            >
              <Form.Group>
                <Form.Label>
                  Home inspection to be completed on or before
                </Form.Label>
                <Form.Control
                  type="date"
                  value={formatDisplayDate(lead.userProperty.homeInspectionDate)}
                  placeholder="MM/DD/YYYY"
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} className="form-item">
              <Form.Group controlId="propertyType">
                <Form.Label>Property Type</Form.Label>
                <Form.Control
                  value={lead.userProperty.propertyType}
                  as="select"
                  disabled
                >
                  {PropertyTypeList.map((item, i) => (
                    <option key={i} value={item}>
                      {item}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col xs={6} md={3} className="form-item">
              <Form.Group controlId="squareFootage">
                <Form.Label>Square Footage</Form.Label>
                <Form.Control
                  type="number"
                  value={lead.userProperty.squareFootage}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col
              xs={6}
              md={3}
              className="d-flex d-align-items-center form-item"
            >
              <Form.Group>
                <Form.Label>Occupancy</Form.Label>
                <div className="form-radios">
                  {OccupancyList.map((item) => (
                    <Form.Check
                      key={item}
                      name="occupancy"
                      label={item}
                      type="radio"
                      id={`inline-radio-${item}`}
                      disabled
                          // eslint-disable-next-line no-bitwise
                      checked={
                            // eslint-disable-next-line no-bitwise
                            !(lead.userProperty.occupancy ^ (item === 'Occupied'))
                          }
                    />
                  ))}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <StreetInput
            label="Address of home to be purchased"
            addresses={lead.userProperty.address}
          />
        </Col>
      </Row>
    </section>
  );
}
