/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Actions } from '../../actions';
import { auth } from '../../firebase';

export default function User() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onAuthStateChanged = async (authUser) => {
    if (!authUser) {
      sessionStorage.removeItem('idToken');
      dispatch(Actions.updateUser());
      navigate('/location');
    }
  };

  useEffect(() => {
    const subscriber = auth.onAuthStateChanged(onAuthStateChanged);
    return subscriber; // unsubscribe on unmount
  }, []);

  return (
    <a
      href="#0"
      onClick={() => auth.signOut()}
    >
      Sign Out
    </a>
  );
}
