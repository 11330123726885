/* eslint-disable no-param-reassign */
import { createAction } from 'redux-actions';
import camelCase from 'lodash/camelCase';
import { upperSnakeCase } from './utils';

// Create load action type variants with do, doing and done prefixed
function updateit(resourceName) {
  return [
    `update ${resourceName}`,
    `updating ${resourceName}`,
    `updated ${resourceName}`,
    `error ${resourceName}`,
  ];
}

// Create load action type variants with do, doing and done prefixed
function doit(resourceName) {
  return [
    `do ${resourceName}`,
    `doing ${resourceName}`,
    `done ${resourceName}`,
    `error ${resourceName}`,
  ];
}

// Create load action type variants with get, loading and loading prefixed
function load(resourceName) {
  return [
    `refresh ${resourceName}`,
    `get ${resourceName}`,
    `loading ${resourceName}`,
    `loaded ${resourceName}`,
    `error loading ${resourceName}`,
  ];
}
// Create multiple load action types (get, loading and loading)
function loadAll(...names) {
  return [].concat(...names.map(load));
}

// Create multiple load action types (do, doing and done)
function doAll(...names) {
  return [].concat(...names.map(doit));
}

// Create multiple load action types (do, doing and done)
function updateAll(...names) {
  return [].concat(...names.map(updateit));
}

// Uper snake case names
function normalize(...names) {
  return names.map(upperSnakeCase);
}

const actionNames = normalize(
  'save referrer',
  'search changed',
  'handle errors',
  'update user',
  ...loadAll(
    'timeline',
    'step info',
    'professionals',
    'leads',
    'lead',
  ),
  ...doAll(
    'save user details',
    'quote',
    'payments',
    'quote mortgates',
    'log in or sign up',
  ),
  ...updateAll(
    'payments',
  ),
);

// The app's redux action types. Each key is the same as the string action type.
// For each action name, the type is generated by upper snake-casing the phrase.
// assert.equal(ActionTypes.EAT_CAKE, 'EAT_CAKE');
export const ActionTypes = actionNames.reduce((actionTypes, actionName) => {
  actionTypes[actionName] = actionName;
  return actionTypes;
}, {});

// Action creators for the app (functions that return Redux/Flux actions).
// let action = Actions.wingardiumLeviosa('ferret');
// assert.deepEqual(action, { type: 'WINGARDIUM_LEVIOSA', payload: 'ferret' });
export const Actions = actionNames.reduce((actions, actionName) => {
  const actionCreatorName = camelCase(actionName);
  actions[actionCreatorName] = createAction(actionName, (x) => x, (x, y) => y);
  return actions;
}, {});
