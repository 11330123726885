import { ActionTypes } from '../actions';

const defaultState = {};

// eslint-disable-next-line default-param-last
export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case ActionTypes.LOADING_LEADS:
        return {
          ...state,
          isLeadsLoading: true,
        };
      case ActionTypes.LOADED_LEADS:
        return {
          ...state,
          leads: action.payload.leads,
          isLeadsLoading: false,
        };

      case ActionTypes.LOADING_LEAD:
        return {
          ...state,
          isLeadLoading: true,
        };
      case ActionTypes.LOADED_LEAD:
        return {
          ...state,
          lead: action.payload.lead,
          isLeadLoading: false,
        };
      default:
        return state;
    }
  } catch (error) {
    console.log('Error in leads reducer:', error);
  }

  return null;
};
