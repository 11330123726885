/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Col, Row, Form,
} from 'react-bootstrap';
import StreetInput from '../../common/StreetInput';
import { MaxPriceList } from '../../../utils/quoteConstants';

export default function Attorneys({ lead }) {
  return (
    <section>
      <Row>
        <Col
          md="auto"
          xs={12}
          className="flex-md-grow-1 flex-md-shrink-1 content"
        >
          <Row>
            <Col xs={12} md={6} className="form-item">
              <Form.Group>
                <Form.Label>Purchase Price</Form.Label>
                <Form.Control
                  value={lead.userProperty.purchasePrice}
                  as="select"
                  disabled
                >
                  {MaxPriceList.map((item, i) => (
                    <option key={i} value={item.value}>
                      {item.value === 0 ? '' : `$${item.name}`}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="form-item-single-checkbox">
              <Form.Group controlId="knowPurchaseAddress">
                <Form.Check
                  type="checkbox"
                  defaultChecked={lead.userProperty.address}
                  label="I know the property address I want to purchase"
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>
          {lead.userProperty.knowPurchaseAddress && (
          <StreetInput
            label="Address of home to be purchased"
            addresses={lead.userProperty.address}
          />
          )}
        </Col>
      </Row>
    </section>
  );
}
