/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import Stripe from 'stripe';
import Loader from '../../utils/Loader';
import Attributes from './LeadDetails/Attributes';
import UserInfo from './LeadDetails/UserInfo';
import MortgageBrokers from './LeadDetails/MortgageBrokers';
import RealEstateAgents from './LeadDetails/RealEstateAgents';
import Attorneys from './LeadDetails/Attorneys';
import HomeInspectors from './LeadDetails/HomeInspectors';
import HomeownersInsurance from './LeadDetails/HomeownersInsurance';
import Movers from './LeadDetails/Movers';
import { professionalCategories } from '../../utils/const';
import { Actions } from '../../actions';
import stripeSessionRequest from './StripeRequests/StripeSessionRequest';
import './index.scss';

export default function Lead() {
  const { leadId } = useParams();
  const dispatch = useDispatch();
  const { lead } = useSelector((state) => state.leads);
  const { sessionUrl } = useSelector((state) => state.payments);

  const [purchased, setPurchased] = useState(false);
  const [sessionRequest, setSessionRequest] = useState(stripeSessionRequest);

  useEffect(() => {
    if (leadId) {
      dispatch(Actions.getLead(leadId));
    }
  }, [leadId]);

  useEffect(() => () => dispatch(Actions.loadedLead({})), []);

  const checkout = async () => {
    // TODO: need to hide stipe secret key used on line 39
    // TODO: modify the sessionRequest to specify different parameters based on
    // different criteria like price, line_items etc
    const stripe = new Stripe('sk_test_51N86A5BL7IibHqipVUQNB3nEZGzHOi8Rf5RPueb6C6lKa5o8wSIEaNfhSPr4w7dUTxz5WGNDGrlizsahGwqz8FHY005TMNtJKR');
    const session = await stripe.checkout.sessions.create(sessionRequest);
    document.location.href = session.url;
  };

  if (sessionUrl) document.location.href = sessionUrl;

  if (!lead) return <Loader />;

  // eslint-disable-next-line max-len

  return (
    <div className="lead_details">
      <h2>
        Seeking
        {' '}
        {professionalCategories.find((p) => p.enum === lead.category)?.indivdual}
      </h2>
      <Attributes lead={lead} />
      {lead.category === 'MortgageBrokers' && (
        <MortgageBrokers lead={lead} />
      )}
      {lead.category === 'RealEstateAgents' && (
        <RealEstateAgents lead={lead} />
      )}
      {lead.category === 'Attorneys' && (
        <Attorneys lead={lead} />
      )}
      {lead.category === 'HomeInspectors' && (
        <HomeInspectors lead={lead} />
      )}
      {lead.category === 'HomeownersInsurance' && (
        <HomeownersInsurance lead={lead} />
      )}
      {lead.category === 'Movers' && (
        <Movers lead={lead} />
      )}
      <div className="user-info-container">
        <UserInfo lead={lead} purchased={purchased} />
        {!purchased && (
        <Button
          className="btn btn-hyponia buy-lead"
          onClick={() => checkout()}
        >
          Buy Lead for $
          {lead.price}
        </Button>

        )}
      </div>
    </div>
  );
}
