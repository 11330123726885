import { ActionTypes } from '../actions';

const defaultState = {};

// eslint-disable-next-line default-param-last
export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case ActionTypes.DONE_PAYMENTS:
        return {
          ...state,
          sessionUrl: action.payload.data,
          done: true,
          isPaymentLoading: false,
        };
      default:
        return state;
    }
  } catch (error) {
    console.log('Error in payments reducer:', error);
  }

  return null;
};
