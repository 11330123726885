import { call, put } from 'redux-saga/effects';
import { Actions, ActionTypes } from '../actions';

import {
  SaveFinancialInfo,
  SaveMove,
  SaveProperty,
  SavePurchaseInfo,
  searchProfessionals,
  UpdateUser,
  ContactProfessionals,
  SubmitQuote,
} from './networks';

function* getProfessionals({ payload }) {
  yield put(Actions.loadingProfessionals());

  const response = yield call(searchProfessionals, payload);

  if (!response.successful) {
    console.log('Error', response.code);
    yield put(Actions.handleErrors(response));
  } else {
    yield put(Actions.loadedProfessionals({
      professionals: response.data,
      category: payload.category,
    }));
  }
}

function* quoteMortgates({ payload }) {
  yield put(Actions.doingQuoteMortgates());

  const response3 = yield call(ContactProfessionals, payload.professionals);
  if (!response3.successful) {
    console.log('Error', response3.code);
    yield put(Actions.handleErrors());
    yield put(Actions.errorQuoteMortgates());
  } else {
    yield put(Actions.loadedProfessionals({
      professionals: response3.data,
      category: payload.category,
    }));
    const response2 = yield call(UpdateUser, payload.contactInfo);

    if (!response2.successful) {
      console.log('Error', response2.code);
      yield put(Actions.handleErrors());
      yield put(Actions.errorQuoteMortgates());
    } else {
      let success = true;
      if (payload.userInfo) {
        const response = yield call(SaveProperty, payload.userInfo);
        if (!response.successful) {
          console.log('Error', response.code);
          success = false;
        } else if (payload.financialInfo) {
          const response1 = yield call(SaveFinancialInfo, payload.financialInfo);
          if (!response1.successful) {
            console.log('Error', response1.code);
            success = false;
          }
        } else if (payload.purchaseInfo) {
          const response1 = yield call(SavePurchaseInfo, payload.purchaseInfo);
          if (!response1.successful) {
            console.log('Error', response1.code);
            success = false;
          }
        }
      } else if (payload.moverInfo) {
        const response = yield call(SaveMove, payload.moverInfo);
        if (!response.successful) {
          console.log('Error', response.code);
          success = false;
        }
      } else if (payload.purchaseInfo) {
        const response = yield call(SavePurchaseInfo, payload.purchaseInfo);
        if (!response.successful) {
          console.log('Error', response.code);
          success = false;
        } else if (payload.financialInfo) {
          const response1 = yield call(SaveFinancialInfo, payload.financialInfo);
          if (!response1.successful) {
            console.log('Error', response1.code);
            success = false;
          }
        }
      }
      if (success) {
        const response = yield call(SubmitQuote, {
          location: payload.search, category: payload.category,
        });
        if (!response.successful) {
          console.log('Error', response.code);
          yield put(Actions.handleErrors());
          yield put(Actions.errorQuoteMortgates());
        } else {
          yield put(Actions.doneQuoteMortgates());
        }
      } else {
        yield put(Actions.handleErrors());
        yield put(Actions.errorQuoteMortgates());
      }
    }
  }
}

export default [
  [ActionTypes.GET_PROFESSIONALS, getProfessionals],
  [ActionTypes.DO_QUOTE_MORTGATES, quoteMortgates],
];
