/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import _ from 'underscore';
import { Actions } from '../../actions';
import { getIconName } from '../../utils';
import Loader from '../../utils/Loader';
import './index.scss';

function GuideCategories() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isTimelineLoading, timeline } = useSelector((state) => state.timeline);
  const search = useSelector((state) => state.search);

  useEffect(() => {
    if (!isTimelineLoading && !_.isEmpty(search) && !timeline) {
      dispatch(Actions.getTimeline(search));
    }
  }, [search]);

  useEffect(() => () => dispatch(Actions.loadedTimeline({})), []);

  if (!timeline) return <Loader />;

  return (
    <ul className="categories">
      {
      timeline && (timeline.steps && timeline.steps.map((step) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <li
          key={step.id}
          onClick={() => navigate(`${step.id}`, {
            state: {
              ...search,
            },
          })}
        >
          {
            !step.completed && <img alt="" className={`icon icon-${getIconName(step.name)}`} />
          }
          {`${step.number}. ${step.name}`}
          <img alt="" className="icon icon-caret icon-right" />
        </li>
      )))
    }
    </ul>
  );
}

export default GuideCategories;
