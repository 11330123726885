/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
import React, { useEffect, useState, useRef } from 'react';
import { useCallbackRef } from 'use-callback-ref';
import {
  Row, Col, Tabs, Tab, Button,
} from 'react-bootstrap';
import _ from 'underscore';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Actions } from '../../actions';
import Tips from './Tips';
import Loader from '../../utils/Loader';
import Info from '../../utils/timelineInfo';
import GuideContent from './GuideContent';
import { getOffset } from '../../utils/const';
import './index.scss';

function Guide() {
  const { category } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isStepInfoLoading, stepInfo } = useSelector((state) => state.timeline);
  const search = useSelector((state) => state.search);

  const [actionButtonMovingClass, setActionButtonMovingClass] = useState('-stuck');
  const [actionButtonStaticClass, setActionButtonStaticClass] = useState('-stuck');
  const [transition, setTransition] = useState(true);
  const transitionRef = useRef();
  transitionRef.current = transition;

  const actionButtonMoving = useCallbackRef(null, attatchEventListener);
  const actionButtonStatic = useRef();

  function attatchEventListener(element) {
    if (element) {
      element.addEventListener(
        'transitionend',
        () => setTransition(false),
      );
    }
  }

  function selectActionButton(transitionLocal) {
    if (actionButtonStatic.current && actionButtonMoving.current) {
      if (getOffset(actionButtonStatic.current) - getOffset(actionButtonMoving.current) < 0) {
        setActionButtonMovingClass('-stuck');
        setActionButtonStaticClass('-stuck');
      } else {
        setActionButtonMovingClass(transitionLocal ? '-transition' : '');
        setActionButtonStaticClass('');
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', () => selectActionButton(transitionRef.current));
    return () => window.removeEventListener('scroll', () => selectActionButton(transitionRef.current));
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () => selectActionButton(transitionRef.current));
    return () => window.removeEventListener('resize', () => selectActionButton(transitionRef.current));
  }, []);

  useEffect(() => {
    if (!isStepInfoLoading && category && !_.isEmpty(search) && !stepInfo) {
      dispatch(Actions.getStepInfo(category));
    }
  }, [category, search]);

  useEffect(() => {
    if (stepInfo) {
      setTimeout(() => selectActionButton(transitionRef.current), 2000);
    }
  }, [stepInfo]);

  useEffect(() => () => dispatch(Actions.loadedStepInfo({})), []);

  if (!stepInfo) return <Loader />;

  const professionalCategory = stepInfo.professionalCategories[0];

  return (
    <div id="details-container">
      <div className="guide-header">
        Estimated Length:
        {' '}
        {stepInfo.durationDays || 1}
        {' '}
        day
        {stepInfo.durationDays > 1 ? 's' : ''}
      </div>
      <Tabs>
        <Tab eventKey="info" title="Info">
          <Row>
            <Col className="tab-content">
              <GuideContent Info={Info} StepNumber={stepInfo.id} />
            </Col>
          </Row>
        </Tab>
        {_.isEmpty(stepInfo?.tips) ? null : (
          <Tab eventKey="stress" title="Tips">
            <Row>
              <Col className="tab-content">
                <Tips tips={stepInfo.tips} />
              </Col>
            </Row>
          </Tab>
        )}
      </Tabs>
      <div className="d-flex justify-content-center">
        <div ref={actionButtonMoving} className={`d-flex flex-column align-items-center py-4 moving-action-button${actionButtonMovingClass}`}>
          <Button
            className="btn btn-hyponia"
            onClick={() => navigate(`/location/professionals/${professionalCategory.category}`, {
              state: {
                ...search,
              },
            })}
          >
            View
            {' '}
            {professionalCategory.name}
          </Button>
        </div>
        <div ref={actionButtonStatic} className={`d-flex flex-column align-items-center py-4 static-action-button${actionButtonStaticClass}`}>
          <Button
            className="btn btn-hyponia"
            onClick={() => navigate(`/location/professionals/${professionalCategory.category}`, {
              state: {
                ...search,
              },
            })}
          >
            View
            {' '}
            {professionalCategory.name}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Guide;
