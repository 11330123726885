export const professionalCategories = [
  {
    id: 1, name: 'Mortgages', enum: 'MortgageBrokers', indivdual: 'a Mortgage Broker',
  },
  {
    id: 2, name: 'Real Estate Agents', enum: 'RealEstateAgents', indivdual: 'a Real Estate Agent',
  },
  {
    id: 3, name: 'Attorneys', enum: 'Attorneys', indivdual: 'an Attorney',
  },
  {
    id: 4, name: 'Home Inspectors', enum: 'HomeInspectors', indivdual: 'a Home Inspector',
  },
  {
    id: 5, name: 'Home Insurance', enum: 'HomeownersInsurance', indivdual: 'a Home Insurer',
  },
  {
    id: 6, name: 'Moving companies', enum: 'Movers', indivdual: 'a Moving Company',
  },
];

export function getOffset(element) {
  const rect = element.getBoundingClientRect();
  return rect.top;
}
