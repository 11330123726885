/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Col, Row, Form,
} from 'react-bootstrap';
import { Actions } from '../../actions';
import { auth } from '../../firebase';

export default function User() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);

  const onAuthStateChanged = async (authUser) => {
    if (!authUser) {
      sessionStorage.removeItem('idToken');
      dispatch(Actions.updateUser());
      navigate('/location');
    }
  };

  useEffect(() => {
    const subscriber = auth.onAuthStateChanged(onAuthStateChanged);
    return subscriber; // unsubscribe on unmount
  }, []);

  return (
    <section>
      <h3>Your Information</h3>
      <Row>
        <Col
          md="auto"
          xs={12}
          className="flex-md-grow-1 flex-md-shrink-1 content"
        >
          <Row>
            <Col xs={12} md={6} className="form-item">
              <Form.Group controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  value={user.firstName}
                  disabled
                />
                {/* <Form.Control.Feedback type="invalid">
                Please enter a valid first name.
              </Form.Control.Feedback> */}
              </Form.Group>
            </Col>
            <Col xs={12} md={6} className="form-item">
              <Form.Group controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  value={user.lastName}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} className="form-item">
              <Form.Group controlId="email">
                <Form.Label>Email (to receive quotes)</Form.Label>
                <Form.Control
                  type="text"
                  value={user.email}
                  disabled
                />
                {/* <Form.Control.Feedback type="invalid">
                Please enter a valid email address.
              </Form.Control.Feedback> */}
              </Form.Group>
            </Col>
            <Col xs={12} md={6} className="form-item">
              <Form.Group controlId="phone">
                <Form.Label>Phone (will not be shared with anyone)</Form.Label>
                <Form.Control
                  type="text"
                  value={user.phone}
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  );
}
